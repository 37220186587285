<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Nuevo usuario</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="clearForm"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pa-3">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Nombre del usuario</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el nombre"
                v-model="newUser.name"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Correo electrónico</p>
              <input
                class="control-input"
                type="email"
                placeholder="Ingrese el correo electrónico"
                v-model="newUser.email"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Contraseña</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese la contraseña"
                v-model="newUser.password"
              />
              <small
                class="ml-3"
                v-if="
                  newUser.password.length > 0 && newUser.password.length < 8
                "
                style="color: red"
                >Mínimo 8 caracteres</small
              >
            </v-col>

            <v-col v-if="type == 'panel'" cols="12" sm="12" md="12">
              <p>Seleccione el tipo de usuario</p>
              <v-autocomplete
                :items="panelRoles"
                v-model="newUser.type"
                outlined
                placeholder="Seleccione el tipo de usuario"
                dense
                hide-details
                item-text="name"
                class="m-0 p-0"
                style="border-radius: 50px"
                item-value="value"
              ></v-autocomplete>
            </v-col>

            <v-col
              v-if="
                ['cargo', 'experience'].includes(type) &&
                newRoles &&
                newRoles.length
              "
              cols="12"
              sm="12"
              md="12"
            >
              <p>Seleccione el rol</p>
              <v-autocomplete
                :items="roles"
                v-model="newUser.role"
                outlined
                placeholder="Seleccione el rol"
                chips
                dense
                hide-details
                class="m-0 p-0"
                style="border-radius: 50px"
                item-value="value"
              ></v-autocomplete>
            </v-col>

            <v-col
              v-if="newUser.role == 'both' && type == 'experience'"
              cols="12"
              sm="12"
              md="12"
            >
              <p>Código de autorización</p>

              <v-text-field
                rounded
                outlined
                :rules="[rules.required, rules.min4]"
                placeholder="Código de autorización"
                hint="Código utilizado para anular boletos utilizados."
                v-model="newUser.authorizationCode"
              />
            </v-col>

            <v-col v-if="businesses" cols="12" sm="12" md="12">
              <p>Seleccione el comercio</p>
              <v-autocomplete
                :items="sortedArrayByName"
                v-model="selectedBusiness"
                outlined
                placeholder="Seleccione el negocio"
                chips
                dense
                hide-details
                class="m-0 p-0"
                style="border-radius: 50px"
                item-value="value"
              >
                <template v-slot:selection="{ item }">
                  <p class="ma-0 pa-0">
                    {{ item.text }}
                    <small v-if="item.cities" style="color: #FF1744"
                      >({{ item.cities }})</small
                    >
                  </p>
                </template>

                <template v-slot:item="data">
                  <div class="mb-1 mt-1 fle">
                    <v-avatar v-if="data.item.logo" left>
                      <v-img
                        :lazy-src="data.item.logo.loading"
                        :src="data.item.logo.original"
                      ></v-img>
                    </v-avatar>
                    <span class="ml-3">
                      {{ data.item.text }}
                      <small v-if="data.item.cities" style="color: #FF1744"
                        >({{ data.item.cities }})</small
                      >
                    </span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="8" xs="8" sm="8" md="8">
              <v-row justify="start">
                <v-switch
                  label="Usuario activo"
                  v-model="newUser.active"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="4" xs="4" sm="4" md="4">
              <v-row justify="end">
                <v-btn class="save-btn mt-3" color="primary" @click="createUser"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "create-user",
  props: ["type", "businesses", "newRoles", "panelRoles", "showPageCine"],
  components: {
    lottie: Lottie,
  },

  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      roles: [
        { text: "Administrador", value: "admin" },
        { text: "Operador", value: "operator" },
      ],

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      cities: [],
      businessesSelect: [],
      selectedBusiness: null,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        min4: (v) => (v && v.length >= 4) || "Mínimo 4 dígitos",
        // emailMatch: () => "The email and password you entered don't match",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      newUser: {
        name: "",
        password: "",
        email: "",
        type: "",
        active: true,
        createdBy: this.$store.state.user[".key"],
      },
    };
  },
  computed: {
    validateUser() {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!this.newUser.name || !this.newUser.email || !this.newUser.password) {
        this.snackbarText = "Debe completar todos los campos.";
        this.snackbar = true;
        return false;
      }

      if (!re.test(this.newUser.email)) {
        this.snackbarText = "Formato de correo incorrecto";
        this.snackbar = true;
        return false;
      }

      if (this.newUser.password.length < 8) {
        this.snackbarText = "La contraseña debe tener mínimo 8 caracteres.";
        this.snackbar = true;
        return false;
      }

      if (!this.type) {
        this.snackbarText = "Tipo de usuario no encontrado.";
        this.snackbar = true;
        return false;
      }

      if (this.businesses && !this.selectedBusiness) {
        this.snackbarText = "Debe seleccionar el comercio.";
        this.snackbar = true;
        return false;
      }

      if (
        this.type == "experience" &&
        this.newUser.role == "both" &&
        !this.newUser.authorizationCode
      ) {
        this.snackbarText = "Debe ingresar el código de autorización.";
        this.snackbar = true;
        return false;
      }

      return true;
    },

    sortedArrayByName() {
      function compare(a, b) {
        if (a.text && b.text && a.text < b.text) return -1;
        if (a.text && b.text && a.text > b.text) return 1;
        return 0;
      }
      return this.businessesSelect.sort(compare);
    },
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    showError(errorCode) {
      switch (errorCode) {
        case "auth/invalid-email":
          this.snackbar = true;
          this.snackbarText = "El correo electrónico es inválido.";
          break;
        case "auth/email-already-exists":
          this.snackbar = true;
          this.snackbarText = "El correo electrónico ingresado ya existe.";
          break;
        case "auth/invalid-password":
          this.snackbar = true;
          this.snackbarText =
            "Clave incorrecta, debe ser una cadena mayor a 6 caracteres";
          break;
        default:
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          break;
      }
    },

    clearForm() {
      this.newUser = {
        name: "",
        password: "",
        email: "",
        type: "",
        active: true,
      };
      this.selectedBusiness = null;
      this.$emit("cancel");
    },

    createUser() {
      if (this.validateUser) {
        this.newUser.type = this.newUser.type ? this.newUser.type : this.type;

        this.newUser.createdAt = new Date();

        if (this.selectedBusiness) {
          this.newUser.businessID = this.selectedBusiness;
        }

        if (this.showPageCine) {
          this.newUser.showPageCine = this.showPageCine;
        }

        this.saving = true;

        var httpUsersV2Create = fb
          .functions()
          .httpsCallable("httpUsersV2Create");
        httpUsersV2Create(this.newUser)
          .then((result) => {
            this.saving = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.saving = false;
            this.showError(error.message);
          });
      }
    },
  },

  async mounted() {
    if (this.businesses) {
      await this.$binding("cities", db.collection("cities"));

      this.businessesSelect = this.businesses.map((item) => {
        let cities = this.cities.filter(
          (c) => item.cities && item.cities.includes(c[".key"])
        );

        let citiesName = cities.map((e) => {
          return e.name;
        });

        return {
          text: item.shortName,
          value: item[".key"],
          cities: citiesName.join(","),
          logo: item.logo,
          isFather: item.isFather,
        };
      });
    }

    if (this.newRoles) {
      this.roles = Object.assign([], this.newRoles);
    }
  },
};
</script>

<style  scoped>
.fle {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>