<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >Comercios de {{ user.name }} {{ user.surname }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <businesses-list-skeleton v-if="loading" />

        <v-container v-if="!loading" fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <a-transfer
                v-if="businesses"
                :listStyle="{
                  height: '420px!important',
                  width: '45%',
                  padding: 0,
                  margin: 0,
                  background: 'white',
                }"
                :data-source="getBusinesses"
                :titles="['Todos los comercios', 'Comercios agregados']"
                :target-keys="localUser.businesses"
                :selected-keys="selectedKeys"
                :show-search="true"
                @search="handleSearch"
                :render="(item) => item.title"
                :disabled="disabled"
                :filter-option="filterOption"
                @change="handleChange"
                @selectChange="handleSelectChange"
                @scroll="handleScroll"
                :locale="{
                  itemUnit: '',
                  itemsUnit: '',
                  notFoundContent: 'Lista vacía',
                  searchPlaceholder: 'Buscar comercio',
                }"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-row align="center" justify="end">
                <v-btn
                  @click="updateBusinesses"
                  class="save-btn"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { mapActions, mapState } from "vuex";
import aTransfer from "ant-design-vue/lib/transfer";
import businessesListSkeleton from "../skeleton/businesses-list-skeleton";
export default {
  name: "edit-businessOwner-businesses",
  props: ["user", "type"],
  components: {
    lottie: Lottie,
    aTransfer,
    businessesListSkeleton,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      localUser: null,
      loading: true,
      businesses: null,

      selectedKeys: [],
      disabled: false,
    };
  },
  computed: {
    getBusinesses() {
      return this.businesses.map((item) => {
        return {
          key: item[".key"],
          title: item.shortName,
        };
      });
    },
  },
  async mounted() {
    await this.$binding(
      "localUser",
      db.collection("appUsers").doc(this.user[".key"])
    );

    let businessQuery = db.collection("businesses").where("deleted", "==", false);

    if( this.type == "experience")  {
      businessQuery = businessQuery.where("type", "==", "experience")
    }

    await this.$binding(
      "businesses",
      businessQuery
    );

    this.loading = false;
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    handleChange(nextTargetKeys, direction, moveKeys) {
      this.localUser.businesses = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    handleScroll(direction, e) {},
    handleDisable(disabled) {
      this.disabled = disabled;
    },
    updateBusinesses() {
      this.saving = true;
      db.collection("appUsers")
        .doc(this.user[".key"])
        .update({
          businesses: this.localUser.businesses,
        })
        .then((ref) => {
          this.saving = false;
          this.$emit("success", this.localUser);
        })
        .catch((err) => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },
  // firestore() {
  //   return {
  //     //  users: db.collection("users").where('uid', '>=', 'ocho-')
  //   };
  // }
};
</script>
